<template>
  

<center>
     <!-- <h1>Not Found!</h1>
    <p>
      <a href="/">Go home?</a>
    </p> -->
  </center>
</template> 

<script>

  export default {
    name: 'NotFound',

  }
  

</script>

<style scoped>

</style>